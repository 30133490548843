import { useFetch } from '../../../../Hooks';
import { FaMapMarkerAlt } from "react-icons/fa";
import { useEffect, useState } from 'react';

export const InputDistrito = ({ setDistrito }) => {

  const { isLoading, getFetch } = useFetch(`${process.env.REACT_APP_BASE_URL}/distritos`);

  const [ datApi, setDatApi ] = useState();
  useEffect(() => {
    getFetch().then( val => setDatApi(val))

  }, [])
  
  const onSelectDistrito = event => {
    const { target } = event;
    const { value } = target;
    const seleccionado = target[value].text;
    setDistrito(seleccionado); //texto del distrito seleccionado
  }

  if(isLoading) return <h2>loading...</h2>

  return (

      <div className='input-district col-12 col-sm-5'>
        <label className='mb-1'><span className='mx-2'><FaMapMarkerAlt/></span>Localidad</label>

        <select className='form-select' name="" id="" onChange={onSelectDistrito}>

          <option value="0" hidden>Buscar distrito</option>
          
          {
            datApi.map( distrito => {
              const { id, nombre } = distrito;

              return (
                <option key={ id } value={ id }>{ nombre }</option>
              )
            })
          }

        </select>

      </div>

  )
}
