import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter} from "react-router-dom";
import { LoginFinalUser } from './auth/pages/LoginFinalUser';
import { store } from './store';
import { AppRouter } from './router/AppRouter';

import './styles/gird.css';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
    <Provider store={ store }>
      <BrowserRouter>
          <AppRouter/>
          {/* <LoginFinalUser/> */}
      </BrowserRouter>
    </Provider>
  // </React.StrictMode>
);

