

export const OptEspecialidad = ({
    optionEspecialidad,
    especialidades,
    setOptionEspecialidad
})=>{

    const handleDeleteEspecialidad = ( index ) =>{
        const deleteEspecialidad = [...optionEspecialidad];
  
        deleteEspecialidad.splice(index, 1);
        setOptionEspecialidad(deleteEspecialidad);
    }

    return(
        <div>
            {
                optionEspecialidad.map( (data, index) =>{
                    const findEsp = especialidades.find( especialidad => especialidad.id === parseInt(data))

                    return(
                        <div key={ index } className="ms-3 my-3">
                            <label>Especialidad {index + 1 }</label>
                            <div className="d-flex">
                                <input 
                                type="text"
                                value={ findEsp.nombre }
                                className="form-control" 
                                disabled
                                />

                                <button
                                    type="button"
                                    className="btn ms-2 btn-outline-danger"
                                    onClick={ ()=>{
                                    handleDeleteEspecialidad(index);
                                    }}
                                > Eliminar 
                                </button>
                            </div>
                        </div>
                    )})
            }

        </div>
    )
}