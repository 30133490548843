import jwt_decode from 'jwt-decode';

export const singInWithGoogle = async ( response ) => {

    try {
        var result = await jwt_decode(response.credential);
        const { email, given_name, picture, jti} = result;

        return {
            ok: true,
            email, given_name, picture, jti
        }

    } catch (error) {
        return {
            ok: false,
            error
        }
    }
}
