import { useState } from 'react';

export const AddServicios = ({
    optServicios,
    setOptServicios
}) => {

    const [valNombreS, setValNombreS] = useState('');
    const [valDescripcionS, setValDescripcionS] = useState('');
    
    const hanldeAddServicio = () => {

        if( valNombreS === '' || valDescripcionS === '' ){
            alert('llenar los campos');

        }else{
            setOptServicios([ ...optServicios, {
                nombre: valNombreS,
                descripcion: valDescripcionS
            }]);
            setValNombreS('');
            setValDescripcionS('');
        }

    };

    const handleDeleteServicio = ( index ) =>{
        const deleteServicio = [...optServicios];

        deleteServicio.splice(index, 1);
        setOptServicios(deleteServicio);
    }


    return (
        <div className="col-12 " >
            {/* <hr /> */}
            <div className='ps-2 py-3 bg-info bg-opacity-25'>
                <label >Servicios *</label>
            </div>
            <div className='ms-3'>
                <div className="d-flex mb-2 mt-1">
                    <input 
                        className="form-control" 
                        placeholder="Nombre Servicio"
                        value={valNombreS}
                        onChange={ ({target})=>{
                            setValNombreS(target.value);
                        } }
                    />

                    <button 
                        type="button" 
                        className="btn btn-outline-primary ms-2"
                        style={{'width':'12rem'}}
                        onClick={ hanldeAddServicio }
                    >Agregar Servicio</button>

                </div>
                <textarea 
                    className="form-control" 
                    placeholder="Descripcion del Servicio"
                    value={valDescripcionS}
                    onChange={ ({target})=>{
                        setValDescripcionS(target.value);
                    } }
                ></textarea>
            </div>

            
            <br />

            {
                optServicios.map( (data, index) =>{
                    const { nombre, descripcion } = data;
                    
                    return (
                        <div key={ index } className='ms-3 mt-3'>
                            <label >Servicio {index + 1 }</label>
                            <div className="d-flex mb-2">
                                <input 
                                    className="form-control" 
                                    value={nombre}
                                    disabled
                                />

                                <button 
                                    type='button' 
                                    className='btn btn-outline-danger ms-2'
                                    onClick={ ()=>{
                                        handleDeleteServicio(index) 
                                    } }
                                >Eliminar</button>
                            </div>

                            <div>
                                <textarea 
                                        className="form-control" 
                                        value={descripcion}
                                        disabled
                                ></textarea>
                            </div>

                        </div>
                    )
                })
            }

        </div>
    )
}
