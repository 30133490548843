import { useState } from 'react'
import { Route, Routes } from 'react-router-dom'
import { LoginFinalUser, RegisterFinalUser } from '../../auth/pages'
import { Navbar } from '../../ui'
import { SearchDoctor,PerfilDoctor, DoctoresList} from '../pages'
import { PrivateRoute } from './PrivateRoute'


export const DoctoresRoute = () => {
  const [distrito, setDistrito] = useState(undefined);

  return (
    <>
        <Navbar/>

        <Routes>
            
            <Route path="loginUser" element={ <LoginFinalUser/> } />
            <Route path="registerUser" element={ <RegisterFinalUser/> } />
            <Route path="inicio" element={ <SearchDoctor setDistrito={setDistrito}/> } />
            <Route path="lista/:especialidad" element={ <DoctoresList distrito={distrito} setDistrito={setDistrito}/> } />
            
            <Route path="perfil/:id" element={ 
              <PrivateRoute>
                <PerfilDoctor number={2}/>
              </PrivateRoute>
             } />

        </Routes>

    </>
  )
}
