import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { InfoDoctor,ServiciosDoctor } from "./index";
import { useFetch } from "../../Hooks";
import './DoctorPage/perfilStyle.css';
import { Valoraciones } from "./DoctorPage/components/Valoraciones";


export const PerfilDoctor = ({ number }) => {

  const { id } = useParams();
  const { getFetch, isLoading } = useFetch(`${process.env.REACT_APP_BASE_URL}/doctores/${id}`);

  const [ datApi, setDatApi ] = useState([]);
  const [ valoracionDoc, setValoracionDoc ] = useState(0);


  const getData = async() => {
    const res = await getFetch();
    setDatApi(res);

  }

  useEffect( () =>{
    getData();

  }, []);

if(isLoading) return <h3>Loading</h3>;

return (
    <>
      <div className="container">
        <div className="header-content info-doctor">
          <InfoDoctor {...datApi} number={number} id_doctor={id} valoracionDoc={valoracionDoc}/>
        </div>

        <div className="container-section">
          <div className="my-5">
            <ServiciosDoctor {...datApi}/>
          </div>

          <div className="opiniones-content my-5">
            <h4 className="ms-3"><b>Opiniones</b></h4>
            <Valoraciones setValoracionDoc={setValoracionDoc}/>
          </div>

        </div>

      </div> 
    </>
  );
};
