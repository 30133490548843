import { useState } from "react";

// const token = JSON.parse(localStorage.getItem( 'token'));

export const useFetch = ( url )=>{

    const [ values, setValues ] = useState({
        datApi: null,
        isLoading: true,
        err: null
    });

    const getFetch = async () => {
        const token = await JSON.parse(localStorage.getItem( 'token'));

        setValues({
            ...values,
            isLoading: true
        })

        const requestOptions = {
            method: 'GET',
            headers: {
                'authorization': token? `Bearer ${token}` : ''
            }
        }

        const res = await fetch(url, requestOptions);
        const dat = await res.json();

        setValues({
            datApi: dat,
            isLoading: false,
            err: null
        })
        return dat;
    } 

    const postFetch = async (_datos) => {
        const token = await JSON.parse(localStorage.getItem( 'token'));
        
        setValues({
            ...values,
            isLoading: true
        })

        const requestOptions = {
            method: 'POST',
            body: JSON.stringify(_datos),
            headers: {
                'Content-Type': 'application/json',
                'authorization': token? `Bearer ${token}` : ''
            }
        }

        const res = await fetch(url, requestOptions);
        const dat = await res.json();

        setValues({
            ...values,
            isLoading: false
        })

        return {
            statusRes: res.status,
            ...dat,
        };
            
     
    }

    const putFetch = async ( _datos )=>{
        const token = await JSON.parse(localStorage.getItem( 'token'));

        const requestOptions = {
            method: 'PUT',
            body: JSON.stringify(_datos),
            headers: {
                "Content-type": "application/json",
                'authorization': token? `Bearer ${token}` : ''
            }
        }

        console.log(token);

        const res = await fetch(url, requestOptions);
        const dat = await res.json();

        console.log(res);

        return{
            statusRes: res.status,
            ...dat
        }

    }

    return {
        ...values,
        values,
        getFetch,
        postFetch,
        putFetch
    }
}