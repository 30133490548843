import { useState, useEffect } from 'react';
import { useFetch, useForm } from '../../Hooks';
import { putDoctorValidado } from './AdminPage/api/putDoctorValidado';
import { statusResponse } from '../../helpers/statusResponse';
import { FaInfoCircle } from "react-icons/fa";

import imgdoctor from '../../assets/doctor.jpg'
import './AdminPage/styleAdmin.css'

export const AdminPage = () => {


    const [doctores, setDoctores] = useState([]);
    const { getFetch, isLoading } = useFetch(`${process.env.REACT_APP_BASE_URL}/doctores/validacion/0`);
    const { handleChange, password, errPassword } = useForm({
        password: '',
    });

    const getDoctors = async() =>{
        const doctors = await getFetch();
        setDoctores(doctors);
    }

    useEffect( ()=>{
        getDoctors();

    }, []);

    const responseAsync = async(id) =>{

        const message = 'credenciales enviadas'
        const response = await putDoctorValidado(password, id);

        statusResponse( response, message );

        if(response.statusRes === 200){
          getDoctors();
        }
    }

    const onValidar = ( id ) => {

        if(!errPassword.error){
            responseAsync(id);

        } else{
            alert('cumplir con el formato');
            
        }

    }

  if( isLoading ) return <h3>Loading...</h3>

  return (
    <div className='container my-4'>
        {
          
          doctores.map( doctor =>{
            const { id, nombres, apellidos, dni, email } = doctor; 

            return(
              <div key={ id } className='card-container my-4'>
                <div className="img-container">
                  <img className='img-format' src={ imgdoctor } />
                </div>

                
                <div className="info-card ms-3">
                    <h5>{ `${nombres} ${apellidos}` }</h5>
                    <span><b>DNI: </b> {dni} </span> <br />
                    <span><b>E-mail: </b> {email} </span>
                </div>

                <div className='validar-section'>
                    <div className='mx-5'>
                        <input 
                            name='password'
                            onChange={ handleChange } 
                            className='form-control'
                            placeholder='Password'
                        />
                        <span className='error' style={{'fontSize': '11px'}}>
                            <FaInfoCircle size={10} className='m-1'/>La contraseña debe tener entre 6 a 16 caracteres, al menos un dígito, al menos una minúscula, al menos una mayúscula. NO puede tener otros símbolos.
                        </span>
                    </div>

                  <button 
                      className='btn btn-success mx-2'
                      onClick={ ()=>{
                          onValidar(id);
                      } }
                  >Validar</button>

                </div>

              </ div>
            );
          })

        }
    </div>
  )
}
