import { useFetch } from '../../../../Hooks';
import { FaHeartbeat } from "react-icons/fa";
import { useEffect, useState } from 'react';

export const InputEspecialidad = ({ setValEspecialidad }) => {

  const {isLoading, getFetch } = useFetch(`${process.env.REACT_APP_BASE_URL}/especialidades`);

  const [ datApi, setDatApi ] = useState();
  
  useEffect(() => {
    getFetch().then( val => setDatApi(val))

  }, [])

  const onSelectEspecialidad = event => {
    const { target } = event;
    const { value } = target;
    setValEspecialidad(value); //id de especialidad seleccionada
  }

  if(isLoading) return <h2>loading...</h2>

  return (

      <div className='input-specialty col-12 col-sm-5'>
        <label className='mb-1'><span className='mx-2'><FaHeartbeat/></span>Especialidad</label>
        <select className='form-select' name="" id="" onChange={onSelectEspecialidad}>
        
        <option value="0" hidden>Buscar especialidad</option>
        {
            datApi.map( especialidad => {
              const { id, nombre } = especialidad;

              return (
                <option key={ id } value={ id }>{ nombre }</option>
              )
            })
          }
        </select>
        
      </div>

  )
}
