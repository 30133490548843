import Box from '@mui/material/Box';
import Rating from '@mui/material/Rating';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useFetch, useForm } from '../../../../Hooks';
import user from '../../../../assets/user.png';

let sum=0;
export const Valoraciones = ( {setValoracionDoc} )=>{

    const { id } = useParams();
    const { isLoading, getFetch } = useFetch ( `${process.env.REACT_APP_BASE_URL}/doctores/${id}/valoraciones` );
    const { nom_paciente, ape_paciente, coment_paciente, handleChange } = useForm({
        nom_paciente:'',
        ape_paciente:'',
        coment_paciente:''
    })

    const [ datApi, setDatApi ] = useState([]);
    const [value, setValue] = useState(0);

    const get = async() =>{
        const res = await getFetch();
        setDatApi(res);
    } 

    useEffect( () =>{
        get();
    }, [])

    const onSubmitValoraciones = async()=>{
        const token = await JSON.parse(localStorage.getItem( 'token'));

        const _datos = {
            'nombres': nom_paciente,
            'apellidos': ape_paciente,
            'comentario': coment_paciente,
            'estrellas': value
        }

        const requestOptions = {
            method: 'POST',
            body: JSON.stringify(_datos),
            headers: {
                'Content-Type': 'application/json',
                'authorization': token? `Bearer ${token}` : ''
            }
        }

        const res = await fetch(`${process.env.REACT_APP_BASE_URL}/doctores/${id}/valoracion`, requestOptions);

        if( res.status===200){
            console.log('entra');
            get();
        }

        datApi.forEach(({estrellas})=>{
            sum += estrellas;
        })
        setValoracionDoc(sum/datApi.length)
    }

    if(isLoading) return <h1>Loading</h1>


    return(
        <div className="ms-3">
            
            <button type='button' className='btn btn-primary my-2' data-bs-toggle="modal" data-bs-target="#exampleModal">Calificar</button>

            <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5" id="exampleModalLabel">Calificar atención del Médico</h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>

                        <div className="modal-body">
                            <div className='text-center'>
                                <img style={{'width':'5rem', 'height':'5rem'}} src={user} alt="" />
                                <Box component="fieldset" my={1} borderColor="transparent">
                                    <h5>¿Recomendaría al doctor?</h5>
                                    <Rating
                                    name="simple-controlled"
                                    value={value}
                                    onChange={(event, newValue) => {
                                        setValue(newValue);
                                    }}
                                    />
                                </Box>
                            </div>

                            <div>
                                <label>Nombres:</label>
                                <input 
                                    type="text" 
                                    name='nom_paciente'
                                    className='form-control'
                                    onChange={ handleChange }
                                    // value={ nom_paciente }
                                />
                            </div>

                            <div className='my-3'>
                                <label>Apellidos:</label>
                                <input 
                                    type="text" 
                                    name='ape_paciente'
                                    className='form-control'
                                    onChange={ handleChange }
                                    // value={ ape_paciente }
                                />
                            </div>

                            <div>
                                <label>Comentario</label>
                                <textarea 
                                    type="text" 
                                    name='coment_paciente'
                                    className='form-control'
                                    onChange={ handleChange }
                                    // value={ coment_paciente }
                                ></textarea>
                            </div>

                        </div>

                        <div className="modal-footer">
                            <button 
                                type="button" 
                                className="btn btn-primary" 
                                data-bs-dismiss="modal"
                                onClick={ onSubmitValoraciones }
                            >Enviar</button>
                        </div>

                    </div>
                </div>
            </div>

            <div className='container-rating'>
                {
                    datApi.map( (valoracion, index) => {
                        const {estrellas, nombres, apellidos, comentario, fecha} = valoracion;

                        return(
                            <div key={index} className='card my-3 px-4'>
                                <div className='d-flex justify-content-between valoracion-header'>

                                    <div className='d-flex mt-4 user-section'>
                                        <img src={user} alt="" />
                                        <div className='ms-2'>
                                            <h6>{`${nombres.split(' ')[0]} ${apellidos.charAt(0)}.`}</h6>
                                            <p style={{'fontSize': 14}}>paciente</p>
                                        </div>
                                    </div>

                                    <div className='valoracion-box opinion'>
                                        <Box component="fieldset" borderColor="transparent">
                                            {/* <label>¿Recomendaría al doctor?</label><br /> */}
                                            <Rating
                                                size="small"
                                                name="simple-controlled"
                                                value={estrellas}
                                                onChange={(event, newValue) => {
                                                    setValue(newValue);
                                                }}
                                                readOnly
                                            />
                                        </Box>
                                        <p style={{'fontSize': 12}}>{fecha}</p>
                                    </div>

                                </div>

                                <p className='ms-1'>{comentario}</p>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}