

export const loginWithGmail = async(user = {}) => {

    const URL = `${process.env.REACT_APP_BASE_URL}/signin/gmail`;
    const requestOptions = {
        method: 'POST',
        body: JSON.stringify(user),
        headers: {'Content-Type': 'application/json'}
    }

    const response = await fetch(URL, requestOptions);
    const data = await response.json();

    return {
        statusRes: response.status,
        ...data
    }

}