import { useSelector } from "react-redux"
import { Navigate } from "react-router";


export const PrivateRoute = ({ children }) => {

    const { status } = useSelector( state => state.auth );


  return ( status === 'autenticado' )
    ? children
    : <Navigate to='/loginUser'/>
}
