import { createSlice } from '@reduxjs/toolkit';

const init = JSON.parse(localStorage.getItem( 'auth'));

export const authSlice = createSlice({
    name: 'auth',
    initialState: {
        status: init? init.status : '', //'revisando' 'no-autenticado' 'autenticado'
        nombres: init? init.nombres : '',
        email: init? init.email : '',
        photo: init?  init.photo : '',
        token: init? init.token: '',
    },
    reducers: {
        login: (state, action) =>{
            state.status = action.payload.status;
            state.nombres = action.payload.nombres;
            state.email = action.payload.email;
            state.photo = action.payload.photo;
            state.token = action.payload.token;
        },
        logout: (state)=>{
            state.status = 'no-autenticado';
            state.nombres = null;
            state.email = null;
            state.photo = null;
            state.token = null;
        },
        isChecking: (state) =>{
            state.status = 'checking';
        }
    }
});


// Action creators are generated for each case reducer function
export const { login, logout, isChecking } = authSlice.actions;