import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

export const statusResponse = ( response = {}, message ) =>{

    const MySwal = withReactContent(Swal);

    if( response.statusRes !== 200 ){
        return(
            MySwal.fire({
                icon: 'error',
                title: 'Error',
                text: response.message
            })
        )
    };

    MySwal.fire({
         icon: 'success',
         text: message
    });

}