import { FaUserMd, FaMedkit, FaHospitalAlt, FaWhatsapp, FaFacebookF, FaInstagram, FaTiktok } from "react-icons/fa";
import {  Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { useFetch } from "../../../../Hooks";
import Rating from '@mui/material/Rating';
import doctor from '../../../../assets/doctor.jpg'

export const InfoDoctor = ({
    id,
    nombres,
    apellidos,
    cmp,
    celular,
    id_sexo,
    trayectoria,
    especialidades,
    foto,
    hospitales,
    redes,
    number,
    id_doctor,
}) => {
    
    const { postFetch } = useFetch(`${process.env.REACT_APP_BASE_URL}/clicks`);
    const { getFetch } = useFetch ( `${process.env.REACT_APP_BASE_URL}/doctores/${id}/valoraciones` );
    const imgPerfil = foto? foto : doctor;
    const url = `https://api.whatsapp.com/send?phone=+51${celular}&text=Hola, me gustaría separar una cita con usted`

    const [ display, setDisplay ] = useState('none');
    const [ rating, setRating ] = useState(0);
    const [ valoraciones, setValoraciones ] = useState([]);

    useEffect(()=>{
        number === 1? setDisplay('') : setDisplay('none');

        getFetch().then(val =>{

            setValoraciones(val);
            let sum = 0; 

            val.forEach( valoracion => {
                const {estrellas} = valoracion;
                sum += estrellas; 
            });

            setRating(sum/val.length);
        })
    },[]);

    const redIcon = (nombre)=>{
        if(nombre === 'Instagram'){
            return (
                <div className="btn" style={{'background': '#8134AF', 'color':'#FFFFFF'}}>
                    <FaInstagram size={20}/>
                </div>
            )
        }
        if(nombre === 'Tiktok'){
            return (
                <div className="btn" style={{'background': '#000000', 'color':'#FFFFFF'}}>
                    <FaTiktok size={20}/>
                </div>
            )
        } 
        if(nombre === 'Facebook'){
            return (
                <div className="btn" style={{'background': '#3B5998', 'color':'#FFFFFF'}}>
                    <FaFacebookF size={20}/>
                </div>
            )
        } else return;

    }

    const getIp = async ()=>{
        const res = await fetch('https://geolocation-db.com/json/');
        const data = await res.json();
        const ip = data.IPv4;

        return ip;
    }

    const onClickRed = (id)=>{
        
        getIp().then( ip=>{
            const _datos={
                ip:ip,
                id: id,
                doctor_id: id_doctor
            }

            postFetch(_datos);
        })
    }

    
    return (
        <div className="container info-section">
            <div className="cuadrado">
                <img className="img-format mt-2" src={imgPerfil} alt={apellidos} />
                <h5 className="mt-3">
                    <span>{id_sexo === 0 ? 'Dra. ' : 'Dr. '}</span>
                    {`${nombres.split(' ')[0]} ${apellidos.split(' ')[0]}`}
                </h5>

                <p>{valoraciones.length} opiniones</p>
                <Rating
                    name="simple-controlled"
                    precision={0.5}
                    value={rating}
                    readOnly
                />

            </div>

            <div className="info-content card">
                <div className="pt-3 ps-4 card-header">
                    <h5>Información</h5>
                </div>

                <div className="card-body">
                    <div className="me-1 ps-4">
                        <b><span><FaUserMd size={15} className='mb-1 me-2'/>CMP: </span></b> 
                        {cmp}
                    </div>

                    <div className="mt-3 ms-4">
                            <b><FaMedkit size={15} className='mb-1 me-2'/>Especialidad(es) </b>
                            <ul >
                                {
                                    especialidades.map( (especialidad, index) => {
                                        const { nombre } = especialidad
                                        return (
                                            <div key={ index } >
                                                { nombre }
                                            </div>
                                        )
                                    })
                                }
                            </ul>


                            <b><span><FaHospitalAlt size={15} className='mb-1 me-2 my-1'/>Establecimiento(s) </span></b>
                            <ul>
                                {
                                    hospitales.map( (data, index) => {
                                        const { nombre } = data
                                        return (
                                            <div key={ index }>
                                                
                                                <div>
                                                    {nombre}
                                                </div>
                                            </div>
                                        )
                                    })
                                }

                            </ul>
                    </div>

                    <div className="my-3 ps-4">
                        <b>Trayectoria Profesional: </b> 
                        <p>{trayectoria}</p>
                    </div>

                    <div className="botones-info">
                        <a target="_blank" href={url}>
                            <div className="btn my-2 ms-4 me-1" style={{background:'#45C655', color:'#FFFFFF'}}>
                                <FaWhatsapp size={20} className='mb-1 me-1'/> Whatsapp
                            </div>
                        </a>
                        <div className="d-flex mt-2">
                            {
                                redes.filter((red) => !!red.url).map( (red, index) =>{
                                    const {id, url, red_social} = red;

                                    return(
                                        <div key={index} className='mx-1'>
                                            <a 
                                                href={url} 
                                                target='_blank' 
                                                onClick = {() => {
                                                    onClickRed(id)
                                                }}
                                            >
                                                    { redIcon(red_social) }
                                            </a>
                                        </div>
                                    )
                                })
                            }
                        </div>
                        

                        <div style={{ display: display }}>
                            <Link to={`/login/formulario/${id}`}>
                                <button className="btn btn-primary mt-2 mx-3">Editar</button>
                            </Link>
                        </div>
                    </div>
                    
                </div>
                
  
            </div>
        </div>
    )
}
