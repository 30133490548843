import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { useFetch, useForm } from "../../Hooks";
import { checkingAuthentication, startGoogleSignIn } from "../../store/auth";
import { useNavigate } from "react-router";
import { FaInfoCircle } from "react-icons/fa";

export const LoginFinalUser = () => {
  
    const { status } = useSelector( state => state.auth );
    const dispatch = useDispatch();
    const navigate = useNavigate();
    
    
    const { handleChange, email, password, errEmail, errPassword } = useForm({
        email: '',
        password: '',
    })

    useEffect(() =>{
        configureGoogle()
    
    }, []);

    const configureGoogle = () => {
        const google = window.google;
        
        google.accounts.id.initialize({
            client_id: '242525123674-u7edads1ndbm64u0hdk5oo7m4ah5vngj.apps.googleusercontent.com',
            callback: onGoogleSignIn
        });
        
        google.accounts.id.renderButton(
            document.getElementById("signInDiv"),
            { theme: 'outline', size: 'large'}
        );

    }

    useEffect(() =>{
        if( status !== 'autenticado' ) return;
        console.log('autenticado');

        navigate(`/inicio`, {
            replace: true,
        });

    }, [status]);
    
    const onSubmit = (event) =>{
        event.preventDefault();

        if( !errEmail.error && !errPassword.error ) {
            dispatch( checkingAuthentication( email, password ) );
        }else{
            alert('Error de escritura');
        }
    }
    
    const onGoogleSignIn = (response) =>{
        dispatch( startGoogleSignIn(response) );
    }

    const handdleRegister = () =>{
        navigate('/registerUser');
    }


    return (
    <div className="section-form-login-user">
        <div className="card card-container form-login p-4">
            <h5 className="my-3">Iniciar Sesion</h5>

            <form onSubmit={ onSubmit } className="row form-login-user mx-3">
                <div className="my-3">
                    <input 
                        type="text"
                        name="email"
                        placeholder="correo electrónico"
                        className="form-control"
                        onChange={ handleChange }
                        required
                    />
                    {errEmail.error && <span className='error'><FaInfoCircle size={10} className='m-1'/>{errEmail.message}</span>}
                </div>
                <div>
                    <input 
                        type="text"
                        name="password"
                        placeholder="contraseña"
                        className="form-control"
                        onChange={ handleChange }
                        required
                    />
                    {errPassword.error && <span className='error'><FaInfoCircle size={10} className='m-1'/>{errPassword.message}</span>}
                </div>
                <div className="mt-3">
                    <center><button type="submit" className="btn">Iniciar Sesión</button></center>
                </div>
                
                <center><div id="signInDiv" onClick={ onGoogleSignIn }></div></center>
                <center><p>¿No tienes una cuenta? <span className="ms-1" onClick={ handdleRegister }>Regístrate</span></p></center>

            </form>

        </div>
    </div>
  )
}
