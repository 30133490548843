import { useForm } from "../../Hooks";
import { useNavigate, Link, json } from "react-router-dom";
import logo from "../../assets/logo.png";
import { useFetch } from "../../Hooks";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { FaInfoCircle } from "react-icons/fa";

export const Login = () => {

    const navigate = useNavigate();
    const MySwal = withReactContent(Swal);
    const { isLoading, postFetch } = useFetch(`${process.env.REACT_APP_BASE_URL}/signin`);
    const { handleChange, email, password, errEmail, errPassword } = useForm({
        email: "",
        password: "",
    });

    const onLogin = async (event) => {
        event.preventDefault();

        const _datos = {
          email: email,
          password: password,
        };

        const res = await postFetch(_datos);

        const { rol, data } = res;
        localStorage.setItem('token', JSON.stringify(res.token));

        if( res.statusRes !== 200 ) {
            return (
                MySwal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: res.message
            }));
        };

        if (rol === "administrador") {
          navigate(`/login/admin`, {
            replace: true,
          });

        } else {
          navigate(`/login/perfilEdit/${data.id}`, {
            replace: true,
          });
        }
      
    };

  const handdleRegister = () => {
    navigate('/registrar');
  }


  return (
    <div className="login-section pt-5">
      <div className="form-login p-5 ">
        <Link to={`/inicio`}>
          <img src={logo} className="mb-4" style={{ width: "8rem" }} />
        </Link>

        <form onSubmit={onLogin} className="row form-login-user">
          <div>
            <input
              type="text"
              name="email"
              value={email}
              placeholder="Email"
              className="form-control"
              onChange={handleChange}
              required
            />
            {errEmail.error && <span className='error'><FaInfoCircle size={10} className='m-1'/>{errEmail.message}</span>}
          </div>

          <div className="my-2">
            <input
              type="password"
              name="password"
              value={password}
              placeholder="Contraseña"
              className="form-control"
              onChange={handleChange}
              required
            />
            {errPassword.error && <span className='error'><FaInfoCircle size={10} className='m-1'/>{errPassword.message}</span>}
          </div>

          <center>
            <button type="submit" className="btn mx-2" >
              Ingresar
            </button>
          </center>

          <center>
            <p>¿No tienes una cuenta? <span className="ms-1" onClick={ handdleRegister }>Regístrate</span></p>
          </center>
        </form>

      </div>
    </div>
  );
};
