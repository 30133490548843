import { useState } from "react";

export const useForm = (initialValues = {}) => {
    const [formState, setFormState] = useState(initialValues);
    const [errText, setErrText] = useState({});
    const [errEmail, setErrEmail] = useState({});
    const [errPassword, setErrPassword] = useState({});
    const [errDni, setErrDni] = useState({});
    const [errCmp, setErrCmp] = useState({});


    const handleChange = ({ target })=>{
        const { name, value } = target;

        const regText = new RegExp('^[a-zA-Z ]*$');
        const regEmail = new RegExp('^[a-zA-Z0-9.!#$%&*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$');
        const regPassword = new RegExp(/^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)[A-Za-zñÑ\d]{6,16}$/);
        const regDni = new RegExp(/^[0-9]{8}$/);
        const regCmp = new RegExp(/^[0-9]{6}$/);

        if( name === 'nombres'){
            if(regText.test(value)){
                setErrText({
                    error: false,
                    message: '',
                })
            }
            else{
                setErrText({
                    error: true,
                    message: 'Escribir solo texto',
                })
            }
        }

        if( name === 'email'){
            if(regEmail.test(value)){
                setErrEmail({
                    error: false,
                    message: '',
                })
            }
            else{
                setErrEmail({
                    error: true,
                    message: 'Escribir correctamente el correo',
                })
            }
        }

        if( name === 'password'){
            if(regPassword.test(value)){
                setErrPassword({
                    error: false,
                    message: '',
                })
            }
            else{
                setErrPassword({
                    error: true,
                    message: 'La contraseña debe tener entre 6 a 16 caracteres, al menos un dígito, al menos una minúscula, al menos una mayúscula. NO puede tener otros símbolos.',
                })
            }
        }

        if( name === 'dni'){
            if(regDni.test(value)){
                setErrDni({
                    error: false,
                    message: '',
                })
            }
            else{
                setErrDni({
                    error: true,
                    message: 'Solo 8 dígitos',
                })
            }
        }

        if( name === 'cmp'){
            if(regCmp.test(value)){
                setErrCmp({
                    error: false,
                    message: '',
                })
            }
            else{
                setErrCmp({
                    error: true,
                    message: 'Solo 6 dígitos',
                })
            }
        }

        setFormState({
            ...formState,
            [ name ]: value
        })

    }

    return{
        ...formState,
        errText,
        errEmail,
        errPassword,
        errDni,
        errCmp,
        handleChange
        
    }
}