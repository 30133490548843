import { useEffect, useState } from 'react';
import { useFetch } from '../../../../Hooks';
import { HospitalAdicional } from '../../index'

export const AddHospital = ({
    setOptHospital,
    optHospital

})=>{

    const { isLoading, getFetch, postFetch } = useFetch( `${process.env.REACT_APP_BASE_URL}/hospitales`);

    const [valHospital, setValHospital] = useState('')
    const [display, setDisplay] = useState("none");
    const [datApi, setDatApi] = useState([]);

    useEffect(() => {
        getFetch().then( val => setDatApi(val));
    }, []);

    const onChangeHospital = (event)=>{
        const { target } = event;
        const { value } = target;
        
        if (value == datApi.length + 1) {
            setDisplay("");
        }
        else{
            setValHospital(value);
        }
    }

    const handleAddHospital = () => {
        const even = element => element === parseInt(valHospital);

        if( optHospital.some(even) ){
            alert('ya esta seleccionado');

        }else{
            setOptHospital([...optHospital, parseInt(valHospital)]);
        }

    };
    
    const handleDeleteHospital = (index) => {
        const deleteHospital = [...optHospital];

        deleteHospital.splice(index, 1);
        setOptHospital(deleteHospital);
    };
    
    if(isLoading) return <h2>loading...</h2>

    return(
        <div className='col-12'>
            
            <div className='ps-2 py-3 bg-info bg-opacity-25'>
                <label >Establecimiento de Salud * </label>
            </div>

            <div className="ms-3 mt-2">
                <div className="d-flex mt-1">
                    <select 
                        className='form-select'
                        id='0'
                        required
                        onChange={ onChangeHospital }
                    >
                        <option value="0" hidden>Seleccionar</option>
                        {
                            datApi.map(hospital => {
                                const {id, nombre} = hospital;
                                
                                return(
                                    <option key={ id } value = { id }>{ nombre }</option>
                                )
                            })
                        }
                        <option value={datApi.length + 1}>Otro</option>
                    </select>

                    <button 
                        type="button" 
                        className="btn btn-outline-primary ms-2"
                        style={{'width':'12rem'}}
                        onClick={ handleAddHospital }
                    >Agregar Hospital</button>

                </div>
            </div>

            <div>
                {
                    optHospital.map( (data, index) =>{
                        const findHosp = datApi.find( hospital => hospital.id === parseInt(data))

                        return(
                            <div key={index} className="ms-3 my-3">
                                <label>Centro de salud {index + 1}</label>
                                <div className="d-flex">
                                    <input 
                                        type="text"
                                        className='form-control'
                                        value={findHosp.nombre}
                                        disabled
                                        />

                                    <button
                                        type="button"
                                        className="btn btn-outline-danger ms-2"
                                        onClick={ () => {
                                            handleDeleteHospital(index);
                                        }}
                                    > Eliminar 
                                    </button>

                                </div>
                            </div>    

                        )
                    })
                }

            </div>

            

            <div style={{ display: display }} className="row g-1 ms-3">
                <HospitalAdicional
                    setDisplay={setDisplay}
                    getHospitales={getFetch}
                    postFetch={postFetch}
                    setDatHospital={setDatApi}
                />
                
            </div>

        </div>

    )
}