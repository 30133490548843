import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { filterDoctors } from "../helpers/filterDoctors";
import { useFetch } from "../../Hooks";
import { CardDoctor, InputDistrito, InputEspecialidad } from "./SearchPage";

export const DoctoresList = ({ distrito, setDistrito }) => {
  const { especialidad } = useParams();
  const { getFetch } = useFetch(`${process.env.REACT_APP_BASE_URL}/doctores`);

  const [doctores, setDoctores] = useState([]);
  const [valEspecialidad, setValEspecialidad]= useState(0)

  useEffect(() => {
    asFunction();
  }, []);

  const asFunction = async () => {
    const datApi = await getFetch();
    const res = await filterDoctors(datApi, especialidad, distrito);
    setDoctores(res);
    setDistrito(undefined);
  };

  const asSearch = async () => {
    const datApi = await getFetch();
    const res = await filterDoctors(datApi, valEspecialidad, distrito);
    setDoctores(res);
    setDistrito(undefined);
  };

  const onSearch = () => {
    console.log('entra');
    asSearch();
  }

  return (
    <>
        <div className="search-container">
            <div className="search-inputs row my-5 p-3">

                <InputEspecialidad setValEspecialidad={setValEspecialidad} />

                <InputDistrito setDistrito={setDistrito} />

                {/* btn */}
                <div className="btn-search-doctor col-2 mt-4">
                    <button 
                        type="button" 
                        className="btn btn-primary mt-1"
                        onClick={onSearch}
                    >Buscar</button>
                </div>

            </div>

        </div>

        <div className="doctores-container my-5">
            <center><h4>Doctores</h4></center>
            <div className="row rows-cols-1 row-cols-3">
                {doctores.map((doctor) => {
                    const { id, apellidos, id_sexo, foto } = doctor;
                    return (
                        <CardDoctor
                            key={id}
                            id={id}
                            apellidos={apellidos}
                            id_sexo={id_sexo}
                            foto={foto}
                        />
                    );
                })}
            </div>
        </div>

        
    </>
  );
};
