import { useEffect, useState } from 'react';
import { useFetch, useForm } from '../../../../Hooks';

export const HospitalAdicional = ({
  setDisplay,
  getHospitales,
  postFetch,
  setDatHospital
}) => {

  const { getFetch, isLoading } = useFetch( `${process.env.REACT_APP_BASE_URL}/distritos`);

  const [datApi, setDatApi] = useState();

  useEffect(() => {
    getFetch().then( val => setDatApi(val));
  }, [])
  

  const { nombreH, direccionH, handleChange } = useForm({
    nombreH: '',
    direccionH: ''
  });

  const [distritoH, setDistritoH] = useState(0);

  const onDistritoH = (event) => {
    const { target } = event;
    const { value } = target;
    setDistritoH( parseInt(value) );
  }

  const onEnviarHospital = () => {
    const _datos = {
      nombre: nombreH,
      distrito: distritoH,
      direccion: direccionH
    }

    setDisplay("none");
    
    if (nombreH === "" || distritoH=== 0 || direccionH === "") {
        alert("Completar este campo");
        return;
        
      } else {
        alert(' Registro Exitoso ');
        postFetch( _datos ).then( ()=>{
          getHospitales().then( val =>{
            setDatHospital(val);
          });

        });
      }
  }

  if(isLoading) return <h2>loading...</h2>

  return (
    <>
      <div className='col-4'>
          <label >Nombre:</label>
          <input type="text" className='form-control' name='nombreH' value={nombreH} onChange={ handleChange }/>
      </div>

      <div className='col-3'>
          <label >Distrito:</label>
          <select className='form-select' value={distritoH} onChange={ onDistritoH }>
              <option value="0">Seleccionar</option>
              {
                  datApi.map( distrito =>{
                      const { id, nombre } = distrito;

                      return(

                          <option key={ id } value={ id }>
                              {nombre}
                          </option>

                      )
                  })
              }
          </select>
      </div>

      <div className='col-4'>
          <label >Dirección:</label>
          <input type="text" className='form-control' name='direccionH' value={direccionH} onChange={ handleChange }/>
      </div>

      <div className='col-1 mt-4 pt-1'>
          <button type='button' className='btn btn-outline-primary' onClick={ onEnviarHospital }>Enviar</button>
      </div>
        
    </>
  )
}
