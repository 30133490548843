import { useState } from 'react';
import { Link } from 'react-router-dom';
import { InputEspecialidad, InputDistrito } from './SearchPage';
import fondo from '../../assets/Vector.png'
import medicinaInterna from '../../assets/medicinaInterna.jpg'
import cardiovascular from '../../assets/cardiovascular.jpg'
import anestesiologia from '../../assets/anestesiologia.png'
import './SearchPage/searchStyle.css'

export const SearchDoctor = ({setDistrito}) => {

    const [valEspecialidad, setValEspecialidad] = useState();

    return (
        <>
            <div className=" mb-5">
                <div className="search-section">
                    <div className='search-inputs-section'>
                        <div className="search-inputs row p-3">

                            <InputEspecialidad
                                setValEspecialidad={ setValEspecialidad }
                                />

                            <InputDistrito
                                setDistrito={ setDistrito }
                                />

                            {/* btn */}
                            <div className='btn-search-doctor col-2 mt-4'>
                                <Link to={`/lista/${valEspecialidad}`}>
                                    <button type='submit' className='btn btn-primary mt-1'>Buscar</button>
                                </Link>
                            </div>

                        </div>

                    </div>

                </div>

                <div className='section-info'>

                    <center><h4 className='my-4'>Especialidades</h4></center>
                    <div className="row rows-cols-1 row-cols-sm-3 row-cols-xxl-4">
                        <div className="card-especialidad card">
                            <img src={medicinaInterna} alt="" />
                            <div className='card-info p-4'>
                                <h5>Pediatría</h5>
                                <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Laborum iure earum maxime</p>
                                <Link to={'/lista/1'}>
                                    <button className='btn'>Ver Especialistas</button>
                                </Link>
                            </div>
                            
                        </div>


                        <div className="card-especialidad card">
                            <img src={cardiovascular} alt="" />
                            <div className='card-info p-4'>
                                <h5>Cirugía de Torax y Cardiovascular</h5>
                                <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Laborum iure earum maxime</p>
                                <Link to={'/lista/2'}>
                                    <button className='btn'>Ver Especialistas</button>
                                </Link>
                            </div>
                            
                        </div>


                        <div className="card-especialidad card">
                            <img src={anestesiologia} alt="" />
                            <div className='card-info p-4'>
                                <h5>Anestesiología</h5>
                                <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Laborum iure earum maxime</p>
                                <Link to={'/lista/3'}>
                                    <button className='btn'>Ver Especialistas</button>
                                </Link>
                            </div>
                            
                        </div>


                    </div>

                </div>
            </div>
        </>
    )
}
