import { useEffect, useState } from "react";
import { useFetch } from "../../../../Hooks";
import { OptEspecialidad } from "../../index";

export const AddEspecialidad = ({
    optionEspecialidad,
    setOptionEspecialidad,
}) => {

    const { isLoading, getFetch, postFetch } = useFetch(`${process.env.REACT_APP_BASE_URL}/especialidades`);

    const [valEspecialidad, setValEspecialidad] = useState("");// Agregar especialidad
    const [display, setDisplay] = useState("none");
    const [valEsp, setValEsp] = useState("");
    const [datApi, setDatApi] = useState([]);

    useEffect(() => {
      getFetch().then( val => setDatApi(val) )
    }, []);

    //Agregar de opcion otro
    const onAgregarEspecialidad = (event) => {
      const { target } = event;
      const { value } = target;

      setValEspecialidad(value);

    };

    //Enviar especialidad adicional
    const onEnviarEspecialidad = async() => {
      const _datos = {
        nombre: valEspecialidad
      }

      setDisplay("none");

      if (valEspecialidad === "") {
        alert("Completar este campo");

      } else {
        
        const response = await postFetch( _datos );
        console.log('response', response)

        if(response.statusRes===200){
          console.log('entra');

          alert('resgistro exitoso');
          setValEspecialidad('');
          getFetch().then( val => setDatApi(val) );
        }
        else{
          console.log('error');
        }

      };

    };

    //Capturar valor del select
    const onEspecialidad = (event) => {
      const { target } = event;
      const { value } = target;
      
      if ( parseInt(value) === datApi.length + 1){
        setDisplay("");

      }else{ setValEsp(value) }
    };

    //opciones de datApi para el perfil del doctor
    const handleAddEspecialidad = () => {
      const even = element => element === parseInt(valEsp);
      console.log(optionEspecialidad.some(even));
      
      if( optionEspecialidad.some( even ) ){
        
        alert('ya esta seleccionado');
      
      }else{
        setOptionEspecialidad([...optionEspecialidad, parseInt(valEsp)]);
      }
      
    }

    if (isLoading) return <h2>loading...</h2>;

    return (
        <div className="col-12">

          {/* Seleccionar Especialidad */}
          <div className='ps-2 py-3 bg-info bg-opacity-25'>
            <label>Especialidad *</label>
          </div>

          <div className="ms-3 mt-2">
            
            <div className="d-flex mt-3" >

              <select 
                  className="form-select"
                  onChange={ onEspecialidad }
                  required >

                  <option value="0" hidden>Seleccionar</option>
                  {
                      datApi.map( especialidad => {
                          const { id, nombre } = especialidad;

                          return (
                          <option key={id} value={id}>
                              {nombre}
                          </option>
                          )})
                  }

                  <option value={datApi.length + 1}>Otro</option>
              </select>

              <button 
                type="button" 
                className="btn btn-outline-primary ms-2"
                style={{'width':'14.5rem'}}
                onClick={ handleAddEspecialidad }
              >Agregar Especialidad</button>

            </div>

          </div>
          
          {/* Opciones Seleccionadas */}
          <OptEspecialidad 
            optionEspecialidad={optionEspecialidad} 
            especialidades={datApi} 
            setOptionEspecialidad={setOptionEspecialidad}
          />

          {/* Agregar Especialidad */}
          <div style={{ display: display }} className="input-group mb-3">

            <br />
            <input
              type="text"
              value={valEspecialidad}
              onChange={onAgregarEspecialidad}
              className = "form-control"
              placeholder="Escribir Especialidad"
              aria-label="Recipient's username" aria-describedby="button-addon2"
            />

            <button 
              type="button" 
              id="button-addon2"
              onClick={onEnviarEspecialidad}
              className="btn btn-outline-primary"
            >Agregar</button>

          </div>

        </div>
    );
};
