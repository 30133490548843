
const NO_SELECTED = undefined;

export const filterDoctors =( datApi, valEspecialidad, valDistrito )=>{
   
    if( valEspecialidad === NO_SELECTED && valDistrito === NO_SELECTED ) return datApi;

    if( valEspecialidad != NO_SELECTED && valDistrito === NO_SELECTED ){

        const filEspDoctors = datApi.filter( doctor =>{
            const { especialidades } = doctor;

            const cumpleCondicion = especialidades.some( especialidad =>{
                const {id} = especialidad;
                return ( String( valEspecialidad ) === String( id ) );
            })
            return cumpleCondicion;
        })
        return filEspDoctors;

    } else{
        if( valEspecialidad === NO_SELECTED && valDistrito != NO_SELECTED ){

            const filDisDoctors = datApi.filter( doctor =>{
                const { hospitales } = doctor;

                const cumpleCondicion = hospitales.some( hospital =>{
                    const {distrito} = hospital;
                    return ( String( valDistrito ) === String( distrito ) );
                })
                return cumpleCondicion;
            })
            return filDisDoctors;

        } else{
            
            const doctoresFiltered = datApi.filter( doctor => {
        
                const { especialidades = [] } = doctor;
                
                const cumpleCondicionEspecialidad = especialidades.some( especialidad => {
                    const { id } = especialidad;

                    return ( String( valEspecialidad ) === String( id ) );
                })
                return cumpleCondicionEspecialidad;
                    
            });
                
            const doctoresFiltered2 = doctoresFiltered.filter( doctor =>{
                const { hospitales = [] } = doctor;

                const cumpleCondicionDistrito = hospitales.some( hospital =>{
                    const { distrito } = hospital; 

                    return( String( valDistrito ) === String( distrito ) );
                
                    })
                return cumpleCondicionDistrito;
                    
            })

            return doctoresFiltered2;
        }
    }

    

}