

export const putImageDoctor = async(image, id)=>{
    
    let formData = new FormData();
    formData.append("image", image)

    const token = await JSON.parse(localStorage.getItem( 'token'));

    const URL = `${process.env.REACT_APP_BASE_URL}/doctores/${id}/foto`; 
    const requestOptions = {
        method: 'PUT',
        body: formData,
        headers: {
            "Authorization": `Bearer ${token}`
        }
    }

    const response = await fetch( URL, requestOptions )
    .then( res => res.json() )
    .then( data => { 
        console.log(data);
    } )

    return ('ok');


}