
export const putDoctorValidado = async ( password, id ) =>{

    const token = await JSON.parse(localStorage.getItem( 'token'));

    const _datos= {
        password: password
    }

    const URL = `${process.env.REACT_APP_BASE_URL}/doctores/${id}/usuarios`;
    const requestOptions = {
        method: 'PUT',
        body: JSON.stringify(_datos),
        headers: {
            "Content-type": "application/json",
            'authorization': token? `Bearer ${token}` : ''
        }
    }

    const res = await fetch(URL, requestOptions);
    const dat = await res.json();

    return {
        statusRes: res.status,
        ...dat
    };
}