import { useEffect, useState } from "react";
import { useParams, Link, useNavigate} from 'react-router-dom';
import { AddEspecialidad, AddHospital, AddServicios } from "./index";
import { putImageDoctor } from ".";
import { useFetch } from "../../Hooks";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

export const AddDoctor = () => {

  const MySwal = withReactContent(Swal)
  const navigate = useNavigate();
  const { id } = useParams();
  const { getFetch, putFetch } = useFetch(`${process.env.REACT_APP_BASE_URL}/doctores/${id}`);
  
  const [sexo, setSexo] = useState("");
  const [optionEspecialidad, setOptionEspecialidad] = useState([]);
  const [optHospital, setOptHospital] = useState([]);
  const [optServicios, setOptServicios] = useState([]);
  const [facebook, setFacebook] = useState('');
  const [instagram, setInstagram] = useState('');
  const [tiktok, setTiktok] = useState('');
  const [image, setImage] = useState({});
  const [apellidos, setApellidos] = useState('');
  const [nombres, setNombres] = useState('');
  const [cmp, setCmp] = useState('');
  const [celular, setCelular] = useState('');
  const [dni, setDni] = useState('');
  const [trayectoria, setTrayectoria] = useState('');
  const [foto, setFoto] = useState('');
  const [fchecked, setFchecked] = useState(0);
  const [mchecked, setMchecked] = useState(0);
  const [display, setDisplay] = useState('none');
  const [uploadColor, setUploadColor] = useState('primary');

  useEffect( ()=>{
    getFetch().then( dat =>{

        const { apellidos, nombres, dni, cmp, celular, id_sexo, trayectoria, foto, especialidades, hospitales, servicios, redes } = dat;
        console.log(foto)

        redes.map( red => {
            if( red.red_social === 'Instagram'){
            setInstagram( red.url );
            }

            if( red.red_social === 'Tiktok'){
            setTiktok( red.url );
            }

            if( red.red_social === 'Facebook'){
            setFacebook( red.url );
            }
        })

        if( especialidades.length !== 0 ){
            
            especialidades.forEach( especialidad =>{
                const valEsp = especialidad.id
                optionEspecialidad.push( valEsp );
            })
        }

        if( hospitales.length !== 0 ){
            
            hospitales.forEach( hospital =>{
                const valHos = hospital.id;
                optHospital.push( valHos );
            })
        }

        if( servicios.length !== 0 ){
            
            servicios.forEach( servicio =>{
                const serv = {
                  nombre: servicio.nombre,
                  descripcion: servicio.descripcion
                }
      
                optServicios.push(serv);
              })
        }
      

    //   if( especialidades.length !== 0 && hospitales.length !== 0 && servicios.length !== 0){

    //   }

      setApellidos(apellidos);
      setNombres(nombres);
      setDni(dni);

      cmp? setCmp(cmp) : setCmp('');
      celular? setCelular(celular) : setCelular('');
      trayectoria? setTrayectoria(dat.trayectoria) : setTrayectoria('');
      foto? setFoto(foto) : setDisplay('');

      if(id_sexo === 0){
        setFchecked('checked');
        setSexo(0)
      } else{
        setMchecked('checked');
        setSexo(1)
      }
    });

  },[]);

  const onChangeCheckradio = ({ target }) => {
    setSexo(target.value);

  };

  const onChangeImage = async( event ) =>{
    const file = event.target.files[0];
    // setFoto(file);
    const img = await putImageDoctor( file, id );
    console.log('img: ',img)
    if( img === 'ok'){
        console.log('entra')
        const infoDoc = await getFetch();
        setFoto( infoDoc.foto );
    }
    setUploadColor('success')
  }

  // SUBMIT FORM
  const handleSubmit = async(e) => {
    e.preventDefault();

    const redes = [
      {
        id: 1,
        url: instagram
      },
      {
        id: 2,
        url: tiktok
      },
      {
        id: 3,
        url: facebook
      }
    ]

    const _datos= {
      nombres: nombres,
      apellidos: apellidos,
      dni: dni,
      cmp: cmp,
      celular: celular,
      id_sexo: sexo,
      trayectoria: trayectoria,
      especialidades: optionEspecialidad,
      hospitales: optHospital,
      servicios: optServicios,
      redes: redes
    }

    // putImageDoctor( image, id );

    const res = await putFetch( _datos );

      if(res.statusRes===200){
        navigate(`/login/perfilEdit/${id}`,{
          replace: true
          });

      } else{
        MySwal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'llenar todos los campos'

        })
      }
  };

    const Nophoto = ()=>{
        return(
            <div>
                <IconButton
                color={uploadColor}
                aria-label="upload picture"
                component="label"
                >

                    <input
                        type="file"
                        name="image"
                        hidden
                        onChange={ onChangeImage }
                    />
                    <div style={{display: display}}>
                        <PhotoCamera />
                    </div>
                </IconButton>

            </div>
        )
    }

    const Siphoto = () =>{
        return (
            <div className="d-flex flex-column">
                <img className='img-format mb-3' src={foto} alt=""/>
                <Button variant="contained" component="label">
                    Cambiar
                    <input 
                        hidden 
                        accept="image/*" 
                        multiple
                        type="file"
                        onChange={ onChangeImage }
                     />
                </Button>
            </div>
        )
    }

  return (
    <>
      <form onSubmit={handleSubmit} className="form-section mb-5">

          <div className="cuadrado">

            {
              foto
              ? <Siphoto/>
              : <Nophoto/>
            }

          </div>

          <div className="form-inputs row mt-5 col-sm-12 col-md-12 col-lg-8 col-xl-8">

            {/* Apellidos */}
            <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6">
              <label>Apellidos: </label>
              <input
                value = {apellidos}
                onChange={ ({target}) =>{
                  setApellidos(target.value)
                }}
                required
                className="form-control"
              />
            </div>

            {/* Nombres */}
            <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6">
              <label>Nombres: </label>
              <input
                value = {nombres}
                onChange={ ({target}) =>{
                  setNombres(target.value)
                }}
                required
                className="form-control"
              />
            </div>

            {/* CMP */}
            <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6">
              <label>CMP: </label>
              <input
                value = {cmp}
                onChange={ ({target}) =>{
                  setCmp(target.value)
                }}
                required
                className="form-control"
              />
            </div>

            {/* Celular */}
            <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6">
              <label>Celular: </label>
              <input
                value = {celular}
                onChange={ ({target}) =>{
                  setCelular(target.value)
                }}
                required
                className="form-control"
              />
            </div>

            {/*dni*/}
            <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6">
              <label>DNI: </label>
              <input
                value = {dni}
                onChange={ ({target}) =>{
                  setDni(target.value)
                }}
                required
                className="form-control"
              />
            </div>

            {/* sexo */}
            <div className="col-6">
              <div onChange={onChangeCheckradio}>
                <label >Género:</label>
                <br />
                <input
                  className="form-check-input"
                  type="radio"
                  name="inlineRadioOptions"
                  value="0"
                  checked={fchecked}
                  readOnly

                />
                <label className="ms-2">Femenino</label>
                <input
                  className="form-check-input ms-2"
                  type="radio"
                  name="inlineRadioOptions"
                  value="1"
                  checked={mchecked}
                  readOnly
                />
                <label className="ms-2">Masculino</label>
              </div>
            </div>

            {/* Trayectoria Profesional*/}
            <div className="col-12">
              <label>Trayectoria Profesional: </label>
              <textarea
                value = {trayectoria}
                onChange={ ({target}) =>{
                  setTrayectoria(target.value)
                }}
                required
                className="form-control"
                rows="3"
              ></textarea>
            </div>

            {/* Especialidad */}
            <AddEspecialidad
              optionEspecialidad={optionEspecialidad}
              setOptionEspecialidad={setOptionEspecialidad}
            />

            {/* Hospital*/}
            <AddHospital
              setOptHospital={setOptHospital}
              optHospital={optHospital}
            />

            {/* Servicio */}
            <AddServicios
              optServicios={optServicios}
              setOptServicios={setOptServicios}
            />

            <label htmlFor="">Instagram: </label>
            <input 
              type="text"
              name="Instagram"
              value={instagram}
              className='form-control'
              onChange={ ({target}) => setInstagram(target.value) } 
            />
            <label htmlFor="">Tiktok: </label>
            <input 
              type="text"
              name="Tiktok"
              value={tiktok}
              className='form-control'
              onChange={ ({target}) => setTiktok(target.value) } 
            />
            <label htmlFor="">Facebook: </label>
            <input 
              type="text"
              name="Facebook"
              value={facebook}
              className='form-control'
              onChange={ ({target}) => setFacebook(target.value) } 
            />

            {/* <button type="button" onClick={ onVer }> Ver2 </button> */}
            <div className="col-12">
              <button className="btn btn-primary" type="submit">
                Guardar
              </button>
              <Link to={`/login/perfilEdit/${id}`}>
                <button className="btn btn-primary ms-3"> Regresar</button>
              </Link>

            </div>

          </div>
      </form>
    </>
  );
};
