
import { useFetch, useForm } from '../../Hooks'
import { FaInfoCircle } from "react-icons/fa";
import { statusResponse } from '../../helpers/statusResponse';
import { useNavigate } from 'react-router';

export const RegisterFinalUser = () => {

    const navigate = useNavigate();

    const { postFetch } = useFetch(`${process.env.REACT_APP_BASE_URL}/pacientes`)
    const { handleChange, nombres, email, password, errText, errEmail, errPassword } = useForm({
        nombres: '',
        email: '',
        password: ''
    });

    const responseAssync = async() => {
        const _datos = {
            "nombres": nombres,
            "email": email,
            "password": password
        };
        const message = 'Registro Exitoso';

        const response = await postFetch(_datos);

        statusResponse( response, message);
    };

    const onSubmit = (event) => {
        event.preventDefault();

        if( !errText.error && !errEmail.error && !errPassword.error ) {
            responseAssync();

        }else{
            alert('Llenar los campos correctamente');
        }

    };

    const handleReturn = () => {
        navigate( '/loginUser');
    }


  return (
    <div className="section-form-login-user">
        <div className="card card-container form-login p-4">
            <h5 className="my-3">Registro</h5>

            <form onSubmit={ onSubmit } className="row form-login-user mx-3">
                <div className='mt-3'>
                    <input 
                        type="text"
                        name="nombres"
                        value={nombres}
                        placeholder="Nombres Completos"
                        className="form-control"
                        onChange={ handleChange }
                        required
                    />
                    {errText.error && <span className='error'><FaInfoCircle size={10} className='m-1'/>{errText.message}</span>}
                    
                </div>
                <div className="my-3">
                    <input 
                        type="text"
                        name="email"
                        value={email}
                        placeholder="correo electrónico"
                        className="form-control"
                        onChange={ handleChange }
                        required
                    />
                    {errEmail.error && <span className='error'><FaInfoCircle size={10} className='m-1'/>{errEmail.message}</span>}

                </div>
                <div>
                    <input 
                        type="text"
                        name="password"
                        placeholder="contraseña"
                        value={password}
                        className="form-control"
                        onChange={ handleChange }
                        required
                    />
                    {errPassword.error && <span className='error'><FaInfoCircle size={10} className='m-1'/>{errPassword.message}</span>}
                </div>

                <div className="mt-3 d-flex">
                    <button type="submit" className="btn">Guardar</button>
                    <button type="button" onClick={ handleReturn } className="btn ms-5">Regresar</button>
                </div>

            </form>

        </div>
    </div>
  )
}
