import { MdReorder } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from 'react-router-dom';
import logo from '../../assets/logo.png'
import { logout } from "../../store/auth";
import { FaUserMd, FaUserCircle } from "react-icons/fa";

export const Navbar = () => {

    const { status, nombres, photo } = useSelector( state => state.auth );
    const dispatch = useDispatch();

    const navigate = useNavigate();

    const handdleLoginDoctor = ()=>{
        navigate('login');
    }

    const handdleRegisterDoctor = () =>{
        navigate('registrar');
    }

    const handdleLoginUser = () =>{
        navigate('loginUser');
    }

    const handdleRegisterUser = () =>{
        navigate('registerUser');
    }

    const handdleLogout = () =>{
        const datos={
            status: 'no-autenticado',
            nombres: '',
            email: '',
            photo: '',
            token: '',
        }

        localStorage.setItem( 'auth', JSON.stringify(datos));
        localStorage.removeItem('token');

        dispatch( logout() );
    }

  return (
    <>
        <div className="navBar">
            <div className="content-nav">
                <div className="navLeft d-flex">
                    <div className="barras">
                        <MdReorder size = {23} />
                    </div>

                    <Link 
                        className="navbar-brand" 
                        to="inicio"
                    >
                        <img src={logo} alt="" />
                    </Link>
                </div>

                <div className="navRight">
                    <div className="nav-noAuth d-flex">

                        <div className="dropdown me-3" style={{ display: (status === 'autenticado') ? 'none' : '' }}>
                            <div className="dropdown-toggle mt-2" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                <FaUserMd size={18} className='mb-1 me-2'/><span className="dropdown-text">Soy doctor</span>  
                            </div>
                            <ul className="dropdown-menu">
                                <li><a className="dropdown-item" onClick={ handdleLoginDoctor }>Iniciar Sesion</a></li>
                                <li><a className="dropdown-item" onClick={ handdleRegisterDoctor }>Registrarse</a></li>
                            </ul>
                        </div>

                        <div className="dropdown" style={{ display: (status === 'autenticado') ? 'none' : '' }}>
                            <div className="dropdown-toggle mt-2" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                <FaUserCircle size={20} className='mb-1 me-2'/><span className="dropdown-text">Soy Paciente</span>
                            </div>
                            <ul className="dropdown-menu">
                                <li><a className="dropdown-item" onClick={ handdleLoginUser }>Iniciar Sesion</a></li>
                                <li><a className="dropdown-item" onClick={ handdleRegisterUser }>Registrarse</a></li>
                            </ul>
                        </div>
                    </div>
                    <div className="nav-auth" style={{ display: (status === 'autenticado') ? '' : 'none' }}>
                        <span>{nombres}</span>
                        <img src={photo} alt="" />

                        <div className="dropdown">
                            <div className="dropdown-toggle ms-2 mt-2" type="button" data-bs-toggle="dropdown" aria-expanded="false"></div>
                            <ul className="dropdown-menu">
                                <li><a className="dropdown-item" onClick={ handdleLogout }>Salir</a></li>
                            </ul>
                        </div>
                    </div>
                    
                </div>
            </div>
            
        </div>
        
    </>
  )
}
