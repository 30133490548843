import { loginWithGmail } from "../../Doctores/helpers/loginWithGmail";
import { statusResponse } from "../../helpers/statusResponse";
import { isChecking, login, logout } from "./authSlice";
import { singInWithGoogle } from "./singInWithGoogle";
import bcrypt from 'bcryptjs';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

export const checkingAuthentication = ( email, password ) => {

    return async( dispatch ) => {

        dispatch( isChecking() );

        const url = `${ process.env.REACT_APP_BASE_URL}/signin`;
        const _datos = {
            email,
            password
        } 
        const requestOptions = {
            method: 'POST',
            body: JSON.stringify(_datos),
            headers: {'Content-Type': 'application/json'}
        }

        const response = await fetch(url, requestOptions);
        const _data = await response.json();

        
        console.log('paciente: ',_data);
        
        const objRes = {
            statusRes: response.status,
            message: _data.message
        }

        if( response.status !== 200 ){
            statusResponse( objRes, '' );
            return;
        }

        const { token, data } = _data;
        const { nombres } = data
        const photo = 'https://cdn-icons-png.flaticon.com/128/149/149071.png'
        
        localStorage.setItem( 'token', JSON.stringify(token));
        
        const datos={
            status: 'autenticado',
            nombres,
            email: '',
            photo,
            token,
        }

        localStorage.setItem( 'auth', JSON.stringify(datos));


        dispatch( login(datos) );
        
    }
}

export const startGoogleSignIn = ( response ) => {

    const MySwal = withReactContent(Swal);

    return async( dispatch ) =>{

        const result = await singInWithGoogle( response );

        const { given_name, email, picture, jti } = result;

        const datos={
            status: 'autenticado',
            nombres: given_name,
            email: email,
            photo: picture,
            token: jti
        }

        const passwordGmail = `${email}p4$$w0rd`

        const hash = await bcrypt.hash(passwordGmail,10);

        const user = {
            nombres: given_name,
            email,
            password: hash,
        }

        const loginGmail = await loginWithGmail( user );

        if( result.ok && loginGmail.statusRes ===200 ){

            localStorage.setItem( 'auth', JSON.stringify(datos));
            localStorage.setItem('token', JSON.stringify(loginGmail.token));

            dispatch( login(datos) );

        } else {

            dispatch( logout() );

            MySwal.fire({
                icon: 'error',
                title: 'Error',
                text: loginGmail.message
            })

        }



    }
}