import { useForm } from "../../Hooks";
import { useState } from "react";
import { Link } from "react-router-dom";
import { useFetch } from "../../Hooks";
import logo from '../../assets/logo.png'
import { FaInfoCircle } from "react-icons/fa";

import '../authStyle.css';
import { statusResponse } from "../../helpers/statusResponse";

export const Registrar = () => {

    const {postFetch} = useFetch(`${process.env.REACT_APP_BASE_URL}/doctores`);
    const {handleChange, cmp, dni, email, errDni, errEmail, errCmp} = useForm({
        cmp:'',
        dni:'',
        email:''
    });
    const {getFetch} = useFetch(`${process.env.REACT_APP_BASE_URL}/doctores/datos/${dni}`);

    const [sexo, setSexo] = useState("");
    const [userName, setUserName] = useState("");
    const [userApe, setUserApe] = useState("");
    const [loading, setLoading] = useState(false);


    const responseAsync = async() =>{

        const _datos = {
            nombres: userName,
            apellidos: userApe,
            dni: dni,
            id_sexo: sexo,
            email: email,
            cmp: cmp
        }
        
        const res = await postFetch( _datos );
        const message = `Sus credenciales se enviarán a su correo luego de verificar la información`;

        statusResponse( res, message );

        console.log('response: ', res);
    }
    const onSubmit = async(e) => {
        e.preventDefault();

        responseAsync();

        
        
        // const message = `Sus credenciales se enviarán a ${data.email} luego de verificar la información`
        
        // const objRes = {
        //     statusRes: res.status,
        //     message: data.message
        // }

        // console.log('objeRes', objRes);


        // statusResponse( objRes, message );
    };

    const onChangeCheckradio = ({ target }) => {
        setSexo(target.value);
    };

    const dniOnBlur = ()=>{
        setLoading(true);
        getFetch().then( val=>{
            setUserApe(val.apellidos);
            setUserName(val.nombres);
            setLoading(false);
        })
    }
    if(loading) return <h1>Loading</h1>

  return (
    <div className="container content-registrar ">

        <div className="form-content p-4 my-5">

            <div className="my-3">
                <img src={logo} style={{width: '5rem'}} />
            </div>

            <form onSubmit={onSubmit} className="mt-2 mb-5">

                <div className="form-inputs row">

                    {/*cmp*/}
                    <div  className="my-2">
                        {/* <label>CMP: </label> */}
                        <input 
                            name="cmp"
                            className="form-control"
                            value={cmp}
                            placeholder='CMP' 
                            onChange={handleChange}
                            required
                        />
                        {errCmp.error && <span className='error'><FaInfoCircle size={10} className='m-1'/>{errCmp.message}</span>}
                    </div>

                    {/*dni*/}
                    <div >
                        {/* <label>DNI: </label> */}
                        <input 
                            name="dni"
                            className="form-control"
                            value={dni}
                            placeholder='DNI' 
                            onChange={handleChange}
                            onBlur={dniOnBlur}
                            required
                        />
                        {errDni.error && <span className='error'><FaInfoCircle size={10} className='m-1'/>{errDni.message}</span>}
                    </div>
                
                    {/* Apellidos */}
                    <div className="my-2">
                        <input
                            name="userApe"
                            className="form-control"
                            placeholder='Apellidos' 
                            value={userApe}
                            onChange={handleChange}
                            disabled
                        />
                    </div>

                    {/* Nombres */}
                    <div >
                        <input
                            name="userNom"
                            className="form-control"
                            placeholder='Nombres' 
                            value={userName}
                            onChange={handleChange}
                            disabled
                        />
                    </div>

                    {/* sexo */}
                    <div className="my-2">
                        <div onChange={onChangeCheckradio}>
                        <label >Género:</label>
                        <br />
                        <input
                            className="form-check-input"
                            type="radio"
                            name="inlineRadioOptions"
                            value="0"
                        />
                        <label className="ms-2">Femenino</label>
                        <input
                            className="form-check-input ms-2"
                            type="radio"
                            name="inlineRadioOptions"
                            value="1"
                        />
                        <label className="ms-2">Masculino</label>
                        </div>
                    </div>

                    <div >
                        <input
                            name="email"
                            className="form-control"
                            placeholder='Email' 
                            onChange={handleChange}
                            required
                        />
                        {errEmail.error && <span className='error'><FaInfoCircle size={10} className='m-1'/>{errEmail.message}</span>}
                    </div>

                </div>

                <div className="mt-3">
                    <button type="submit" className="btn btn-primary mx-2">Enviar</button>
                    <Link to={`/login`}>
                        <button type="button" className="btn btn-primary mx-2">Regresar</button>
                    </Link>

                </div>

            </form>

        </div>
    </div>
  )
}
